/*--------------------  Revolution Layer Style Start  -------------------*/

/* --該当なし */

#rev_slider_24_1 .metis.tparrows {
  background: white;
  padding: 10px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  width: 60px;
  height: 60px;
  box-sizing: border-box;
}

#rev_slider_24_1 .metis.tparrows:hover {
  background: rgba(255, 255, 255, 0.75);
}

#rev_slider_24_1 .metis.tparrows:before {
  color: black;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

#rev_slider_24_1 .metis.tparrows:hover:before {
  transform: scale(1.5);
}

/* 該当なし-- */

/*-------------------- Section title Start  -------------------*/
.section-title {
  margin-bottom: 35px;
}

.section-title h2.title {
  text-align: center;
  margin-bottom: 8px;
  text-transform: capitalize;
}

.sub-title {
  margin-top: -10px;
  margin-bottom: 40px;
}

/*--------------------  Categoru Slider Start  -------------------*/

/* --該当なし */

.ciyashop_banner
  .ciyashop_banner-content
  .ciyashop_banner-content-inner-wrapper
  .ciyashop_banner-text-wrap-1
  .ciyashop_banner-text.tag-text {
  font-size: 0.23em;
  color: #323232;
  background-color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 6px;
  line-height: 30px;
  font-weight: 600;
  padding: 7px 15px;
  margin-bottom: 5px;
}

.ciyashop_banner .ciyashop_banner-content-inner-wrapper {
  font-size: 70px;
}

.ciyashop_banner
  .ciyashop_banner-content
  .ciyashop_banner-content-inner-wrapper
  .ciyashop_banner-text {
  font-weight: 700;
  font-style: normal;
  font-size: 0.42em;
  color: #323232;
  line-height: 55px;
}

.ciyashop_banner
  .ciyashop_banner-content
  .ciyashop_banner-content-inner-wrapper
  .ciyashop_banner-btn-wrap.ciyashop_banner-btn-style-link
  .ciyashop_banner-btn {
  color: #323232;
}

.ciyashop_banner.ciyashop_banner-style-deal-1
  .ciyashop_banner-content
  .ciyashop_banner-content-wrapper
  .ciyashop_banner-content-inner-wrapper
  .ciyashop_banner-text-wrap
  .ciyashop_banner-text
  h2 {
  font-size: 62px;
  font-weight: 600;
}

.ciyashop_banner.ciyashop_banner-style-deal-1
  .ciyashop_banner-content-inner-wrapper
  .ciyashop_banner-btn-wrap.ciyashop_banner-btn-style-flat
  .ciyashop_banner-btn {
  background: #ffffff;
}

/* 該当なし-- */

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1300px;
  }
}
