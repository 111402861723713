body {
  margin: 0;
  padding: 0;
}

.underline {
  text-decoration: underline;
}

.font-bold {
  font-weight: bold;
}
