/***************************
    REVOLUTION SLIDER
***************************/

/* ==該当なし */

#rev_slider_15_1 .metis.tparrows {
  background: white;
  padding: 10px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  width: 60px;
  height: 60px;
  box-sizing: border-box;
}

#rev_slider_15_1 .metis.tparrows:hover {
  background: rgba(255, 255, 255, 0.75);
}

#rev_slider_15_1 .metis.tparrows:hover:before {
  transform: scale(1.5);
}

#rev_slider_15_1 .metis.tparrows:before {
  color: black;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.single-img-wrapper {
  position: relative;
}

.single-img-wrapper .text-wrapper {
  text-align: center;
  padding: 20px 15px;
}

.single-img-wrapper .text-wrapper h5 {
  margin-bottom: 0;
}

.single-img-wrapper .text-wrapper p {
  margin-bottom: 0;
}

.single-img-wrapper .single-img-content {
  position: absolute;
  top: 50px;
  left: 50px;
  width: 220px;
}

.single-img-wrapper .single-img-content.content-vbottom {
  bottom: 60px;
  top: inherit;
}

.single-img-wrapper.single-img-wrapper-1 {
  padding-right: 10%;
}

.single-img-wrapper.single-img-wrapper-1 .text-wrapper {
  margin-top: 30px;
}

.single-img-wrapper.single-img-wrapper-1 .single-img-content {
  left: 220px;
  top: 0;
}

.single-img-wrapper.single-img-wrapper-2 {
  padding-left: 10%;
}

.single-img-wrapper.single-img-wrapper-2 .text-wrapper {
  margin-bottom: 30px;
}

.single-img-wrapper.single-img-wrapper-2 .single-img-content {
  left: 0px;
}

.single-img-wrapper.single-img-wrapper-3 {
  padding-left: 10%;
}

.single-img-wrapper.single-img-wrapper-3 .text-wrapper {
  margin-top: 95px;
}

.single-img-wrapper.single-img-wrapper-3 .single-img-content {
  top: inherit;
  left: 0;
  bottom: 50px;
  width: 100%;
  text-align: center;
  padding-left: 10%;
  margin-bottom: 70px;
}

.single-img-wrapper.single-img-wrapper-3 .single-img-content .border-line {
  margin-right: 40%;
  margin-left: 40%;
}

.border-line {
  height: 2px;
  margin-top: 60px;
  margin-right: 80%;
  margin-bottom: 10px;
  background-color: #c1eade;
}

/* 該当なし-- */

.bg-orange {
  background: rgba(251, 248, 243);
}
